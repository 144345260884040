/* General Styles */
body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: #f9f9f9;
    color: #333;
  
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }
  /* Only Apply Background to Contact Page */
.quote-body {
  background-image: url('./assets/streetview.jpg'); /* Ensure path is correct */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: Arial, sans-serif;
  color: white;
  min-height: 100vh; /* Full height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark Overlay for Better Contrast */
.quote-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Darkens the background */
  z-index: -1;
}
  
  /* Quote Page Container */
  .quote-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #000000, #010101);
    max-width: 570px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #000000;
    font-family: Arial, sans-serif;

  }
  .inventory-dropdown {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Chatbot Container */
  .chatbot-container {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 20px;
  }
  
  /* Chat Messages Area */
  .chatbot-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #f7f7f7;
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
}
  
  /* Chat Message Styles */
  .message {
    display: flex;
    align-items: flex-start;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message.bot {
    justify-content: flex-start;
  }
  
  .message p {
    margin: 0;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
    font-size: 14px;
  }
  
  .message.user p {
    background-color: #c39d12;
    color: white;
  }
  
  .message.bot p {
    background-color: #e6e6e6;
    color: #333;
  }
  
  /* Chatbot Input Area */
  .chatbot-input {
    padding: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #ddd;
  }
  
  .chatbot-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .chatbot-input button {
    padding: 10px 15px;
    background-color: #c39d12;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .chatbot-input button:hover {
    background-color: #c39d12;
  }
  
  /* Options Section */
  .service-options, .inventory-options, .supplies-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
  }

  .supplies-options button,
.selected-inventory button {
  background-color: #c39d12;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.supplies-options button:hover,
.selected-inventory button:hover {
  background-color: #c39d12;
}

.selected-inventory input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

  
  .service-options h3,
  .inventory-options h3,
  .supplies-options h3 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  
  .service-options button,
  .inventory-options button,
  .supplies-options button {
    flex: 1 1 calc(50% - 10px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: rgb(14, 13, 13);
    cursor: pointer;
    font-size: 14px;
    font-family: "Palatino Linotype", Palatino, "Century Schoolbook L", "Times New Roman", "serif";
    transition: background-color 0.3s, color 0.3s;
  }
  
  .service-options button:hover,
  .inventory-options button:hover,
  .supplies-options button:hover {
    background-color: #c39d12;
    color: white;

  }
  
  /* Submit Button */
  .submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #deb409;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .submit-button:hover {
    background-color: #deb409;
  }
  
  /* Scrollbar Styles */
  .chatbot-messages::-webkit-scrollbar {
    width: 8px;
  }
  
  .chatbot-messages::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 4px;
  }
  
  .chatbot-messages::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
  
  /* Loader Animation */
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bouncing-dots {
    display: flex;
    justify-content: space-between;
    width: 60px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #deb409;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Large Thank You Message */
  .thank-you-message {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #deb409;
    margin-top: 20px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .chatbot-container {
      height: auto;
      max-height: 90vh;
    }
  
    .chatbot-input input {
      font-size: 12px;
    }
  
    .chatbot-input button {
      font-size: 12px;
      padding: 8px 12px;
    }
  
    .service-options button,
    .inventory-options button,
    .supplies-options button {
      flex: 1 1 100%; /* Full width for smaller screens */
    }
  
    .submit-button {
      font-size: 14px;
      padding: 8px 14px;
    }
  }
  
  .quote-notice{
    font-size:small;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .quote-notice p {
    color: black;
  }

  .chatbot-header{
    font-size: larger;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 2%;
    margin-bottom: 6%;
  }

  .quote-container {
    background-image: url("./assets/streetview.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Description Section */
  .quote-description {
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    animation: fadeIn 1s ease-in-out;
    margin-top: 40px;
  }
  
  .quote-description h1 {
  
    color: #d4af37;
    margin-bottom: 10px;
  }
  
  .quote-description p {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
  }

  .quote-description strong{
    font-size: 30px;
    color: #d4af37;
  }

.icon {
  font-size: 20px;
  color: #d4af37;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* 🚀 RESPONSIVE DESIGN FOR MOBILE SCREENS */
@media (max-width: 768px) {
  

  .quote-description {
    width: 95%;
    padding: 15px;
  }

  .quote-description h1 {
    font-size: 22px;
  }

  .quote-description p {
    font-size: 16px;
  }

  .quote-chat-container {
    width: 95%;
    padding: 15px;
  }

  .messages {
    height: 300px;
  }

  .message p {
    font-size: 14px;
  }

  .chat-input {
    flex-direction: column;
  }

  .chat-input input {
    width: 100%;
    margin-bottom: 10px;
  }

  .chat-input button {
    width: 100%;
  }

  .service-options button,
  .inventory-options button,
  .supplies-options button {
    flex: 1 1 100%;
  }

  .submit-button {
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
  }

  .hero-content h1{
    color: black;
    margin-top: 50px;
    background-color: rgba(185, 146, 6, 0.319);
  }

  .hero-content p{
    color: white;
    font-weight: bold;
  }

  .chatbot-container{
    height: 1220px;
  }
  .quantity-input{
    color: black;

  }

  .selected-inventory ul{
    color: black;
    list-style-type: none;
    margin-right: 40px;
  
  }


  
}
