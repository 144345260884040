/* General Styling */
.about-container {
  font-family: "Arial", sans-serif;
  color: white;
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(to right, #1a1a1a, #222222);
}

/* Hero Section */
.hero-section {
  background-image: url("./assets/8.png"); /* Add Your Image */
  background-size: cover;
  background-position: center;
  padding: 100px 20px;
  text-align: center;
  color: white;
  animation: fadeIn 1.5s ease-in-out;
  
}

.fade-in{
  background-color: #1a1a1a;
}

.hero-section h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  
}

.hero-section p {
  font-size: 18px;
  font-style: italic;
}

/* Content Sections */
.content-section,
.why-choose-us,
.values-section,
.cta-section {
  margin: 40px auto;
  max-width: 1000px;
}

/* Grid Layout */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  text-align: left;
}

.grid-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
  transition: transform 0.3s;
}

.grid-item:hover {
  transform: scale(1.05);
}

/* Values Grid */
.values-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.value-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.value-card:hover {
  transform: scale(1.1);
}

/* Call to Action Section */
.cta-section {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
}

.contact-btn {
  padding: 15px 25px;
  background-color: #d4af37;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.contact-btn:hover {
  background-color: white;
  color: black;
  transform: scale(1.1);
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .hero-section h1 {
    font-size: 32px;
  }

  .hero-section p {
    font-size: 16px;
  }
}
