/* Smooth Fade-in and Slide-in Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { opacity: 0; transform: translateX(-30px); }
  to { opacity: 1; transform: translateX(0); }
}

/* Only Apply Background to Contact Page */
.contact-page  {
  background-image: url('./assets/11.png'); /* Ensure path is correct */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: Arial, sans-serif;
  color: white;
  min-height: 100vh; /* Full height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark Overlay for Better Contrast */
.contact-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Darkens the background */
  z-index: -1;
}




/* Contact Container */
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 50px auto;
  padding: 40px;
  gap: 40px;
  background: rgba(0, 0, 0, 0.8); /* Slightly darker black for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  animation: fadeIn 1s ease-in-out;
}

/* Contact Info */
.contact-info {
  flex: 1;
  margin-top: 50px;
  max-width: 400px;
  margin-left: 50px;
  animation: slideIn 1s ease-in-out;
}

/* Text and Headings */
.contact-info h2,
.contact-form h2,
.contact-info h2,
.contact-info p,
.contact-details p,
.contact-details a,
.social-links h3,
.social-links a,
.contact-form p,
.contact-form label {
  color: white !important; /* Force white text */
  font-weight: bold;
}

/* Contact Details */

.contact-details{
  margin-top: 45px;

}
.contact-details p {
  font-size: 18px;
  margin: 10px 0;
}

.contact-details a {
  color: white;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Social Media Links */
.social-links {
  margin-top: 40px;
}

.social-links h3 {
  font-size: 22px;
  color: white;

}

.social-links a {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 10px 0;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.social-links a svg {
  margin-right: 10px;
  font-size: 24px;
  color: #d4af37; /* Gold */
}

.social-links a:hover {
  color: #d4af37;
}

/* Contact Form */
.contact-form {
  flex: 1;
  max-width: 500px;
  animation: fadeIn 1.2s ease-in-out;
}

/* Form Styling */
.contact-form label {
  font-size: 18px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 150%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1); /* Light input background */
  color: white;
  border: 1px solid white;
}

.contact-form textarea {
  height: 120px;
}

/* Add Input Focus Effect */
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #d4af37;
  outline: none;
  box-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

/* Submit Button Centered */
.submit-btn {
  width: 100%;
  max-width: 200px; /* Adjust width */
  padding: 12px;
  background-color: #d4af37;
  color: black;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-top: 10px; /* Space above the button */
  text-align: center;
  margin-left: 130px;
}

.submit-btn:hover {
  background-color: #b0892e;
  transform: scale(1.05);
}

/* Status Message */
.status-message {
  margin-top: 10px;
  font-weight: bold;
  animation: fadeIn 1s ease-in-out;
  color: white;
  margin-left: 105px;
}

/* =================== 📱 Mobile Responsive Styling (Placed at Bottom) =================== */

/* Tablets and Smaller Screens */
@media screen and (max-width: 1024px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    margin: auto;
  }

  .contact-info {
    max-width: 100%;
    margin-left: 0;
  }

  .contact-info h2 {
    font-size: 24px;
  }

  .contact-info p {
    font-size: 16px;
  }

  .contact-details {
    margin-top: 20px;
    padding: 15px;
  }

  .contact-details p {
    font-size: 18px;
    text-align: center;
  }

  .social-links {
    margin-top: 15px;
  }

  .social-links h3 {
    font-size: 25px;
    text-align: center;
  }

  .social-links a {
    font-size: 20px;
    margin-left: 37%;
  }

 .service-card a {
  margin-left: -30px;
 }

  .submit-btn {
    font-size: 16px;
    padding: 10px;
  }

}

/* Mobile Screens (Max Width: 768px) */
@media screen and (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-info h2 {
    font-size: 22px;
  }

  .contact-info p {
    font-size: 14px;
  }

  .contact-details {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 10px;
  }

  .contact-details p {
    font-size: 16px;
  }

  .social-links {
    margin-top: 10px;
  }

  .social-links h3 {
    font-size: 25px;
    
  }
 
  .submit-btn {
    font-size: 14px;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .status-message {
    font-size: 14px;
  }
}

/* Small Mobile Screens (Max Width: 480px) */
@media screen and (max-width: 480px) {
  .contact-container {
    padding: 15px;
  }

  .contact-info h2 {
    font-size: 20px;
  }

  .contact-info p {
    font-size: 12px;
  }

  .contact-details {
    padding: 8px;
  }

  .contact-details p {
    font-size: 14px;
  }

  .social-links h3 {
    font-size: 25px;
  }



  .submit-btn {
    font-size: 12px;
    padding: 10px;
  }

  .status-message {
    font-size: 12px;
  }
}

/* ================== Contact Form Styling ================== */
.contact-form {
  flex: 1;
  max-width: 500px;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7); /* Darker background for contrast */
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  animation: fadeIn 1.2s ease-in-out;
  text-align: center;
}

/* Contact Form Heading */
.contact-form h2 {
  font-size: 28px;
  color: #d4af37; /* Gold */
  margin-bottom: 20px;
  font-family: "Baskerville", "Palatino", serif;
}

/* Contact Form Labels */
.contact-form label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin: 15px 0 5px;
  color: white;
}

/* Contact Form Inputs & Textarea */
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #d4af37; /* Gold border */
  background: rgba(255, 255, 255, 0.1); /* Light background */
  color: white;
  font-size: 16px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

/* Add focus effect */
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #b0892e;
  box-shadow: 0 0 8px rgba(212, 175, 55, 0.5);
  outline: none;
}

/* Contact Form Textarea */
.contact-form textarea {
  height: 120px;
  resize: none;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  max-width: 220px;
  padding: 14px;
  margin-top: 15px;
  background: #d4af37;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: block;
  text-align: center;
}

.submit-btn:hover {
  background: #b0892e;
  transform: scale(1.05);
}

/* Status Message */
.status-message {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  animation: fadeIn 1s ease-in-out;
}

/* ================== Mobile Responsive Styling ================== */
@media screen and (max-width: 768px) {
  .contact-form {
    padding: 20px;
    max-width: 100%;
  }

  .contact-form h2 {
    font-size: 24px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
    padding: 10px;
  }

  .submit-btn {
    font-size: 16px;
    width: 100%;
  }
  .social-links h3 {
    font-size: 25px;
    text-align: center;
  }
}

