/* FAQ Page Styling */
.faq-container {
    max-width: 900px;
    margin: 80px auto;
    padding: 40px;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(212, 175, 55, 0.3);
    font-family: "Baskerville", "Palatino", serif;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Page Title */
  .faq-container h1 {
    text-align: center;
    color: #d4af37;
    font-size: 36px;
    font-weight: bold;
  }
  
  /* Intro Paragraph */
  .faq-intro {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .faq-intro a {
    color: #d4af37;
    text-decoration: none;
    font-weight: bold;
  }
  
  .faq-intro a:hover {
    text-decoration: underline;
  }
  
  /* FAQ List */
  .faq-list {
    margin-top: 20px;
  }
  
  /* FAQ Item */
  .faq-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  /* Active FAQ */
  .faq-item.active {
    background: rgba(255, 255, 255, 0.2);
  }
  
  /* Question Styling */
  .faq-question {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: #d4af37;
  }
  
  /* FAQ Answer */
  .faq-answer {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
    color: white;
  }
  
  /* Expand/Collapse Icon */
  .faq-icon {
    font-size: 22px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* 📱 Responsive Design */
  @media screen and (max-width: 768px) {
    .faq-container {
      padding: 30px;
      margin: 60px auto;
    }
  
    .faq-container h1 {
      font-size: 28px;
    }
  
    .faq-question {
      font-size: 18px;
    }
  
    .faq-answer {
      font-size: 16px;
    }
  }
  