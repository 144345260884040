/* General Page Styling */
.terms-container, .privacy-container {
    max-width: 900px;
    margin: 80px auto;
    padding: 40px;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(212, 175, 55, 0.3);
    font-family: "Baskerville", "Palatino", serif;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Heading Styles */
  .terms-container h1, .privacy-container h1 {
    text-align: center;
    color: #d4af37; /* Gold */
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: bold;
  }
  
  /* Section Headings */
  .terms-container h2, .privacy-container h2 {
    font-size: 24px;
    color: #d4af37;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  /* Paragraph & Text */
  .terms-container p, .privacy-container p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  /* Email Link */
  .terms-container a, .privacy-container a {
    color: #d4af37;
    text-decoration: none;
    font-weight: bold;
  }
  
  .terms-container a:hover, .privacy-container a:hover {
    text-decoration: underline;
  }
  
  /* Smooth Fade-in Animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .terms-container, .privacy-container {
      padding: 30px;
      margin: 60px auto;
    }
  
    .terms-container h1, .privacy-container h1 {
      font-size: 28px;
    }
  
    .terms-container h2, .privacy-container h2 {
      font-size: 20px;
    }
  
    .terms-container p, .privacy-container p {
      font-size: 16px;
    }
  }
  