/* =================== 🌟 Luxury Animated Navbar Styling =================== */
.navbar {
  background: linear-gradient(135deg, #1a1a1a, #222222);
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

/* Navbar Scroll Effect */
.navbar.scrolled {
  background: rgba(26, 26, 26, 0.9);
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
}

.navbar-logo{
  margin-left: 15px;
}

/* Navbar Container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

/* Logo */
.logo {
  width: 180px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.15);
}

/* Navigation Links */
.nav-links {
  display: flex;
  list-style: none;
  gap: 35px;
  font-family: "Baskerville", "Palatino";
  font-weight: bold;
  font-size: 22px;
  color: white;
}

.nav-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 20px;
  border-radius: 6px;
  color: rgba(220, 190, 20, 0.8);
  transition: all 0.3s ease-in-out;
  position: relative;
}

/* Hover Effect */
.nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  left: 0;
  background-color: rgba(220, 190, 20, 0.8);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
}

.nav-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.2);
  cursor: pointer;
}

/* Active Link Highlight */
.nav-link.active {
  background: #d4af37;
  border-radius: 5px;
  color: black;
  transition: all 0.3s ease-in-out;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

.menu-icon {
  font-size: 30px;
  color: white;
}

/* Mobile Navbar */
.nav-links-mobile {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background: #1a1a1a;
  color: white;
  margin-top: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  list-style: none;
  transition: all 0.3s ease-in-out;
  transform: translateY(-100%);
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.2);
}

/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  margin: 0 -320px;
}

.search-bar:hover {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.3);
}

.search-bar form {
  margin-left: 100px;
}

.search-bar input {
  border: none;
  background: transparent;
  color: white;
  outline: none;
  padding: 8px 15px;
  font-size: 16px;
}

.search-bar button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  padding: 5px;
  font-size: 18px;
}

.search-bar button:hover {
  color: #d4af37;
}

/* =================== 📱 Mobile Responsive Styling (All Placed at Bottom) =================== */

@media screen and (max-width: 1024px) {
  .navbar {
    padding: 10px 20px;
  }

  .nav-links {
    gap: 25px;
    font-size: 18px;
  }

  .nav-link {
    padding: 15px;
    font-size: 16px;
  }

  .logo {
    width: 140px;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .nav-links-mobile {
    flex-direction: column;
    width: 100%;
    background: #1a1a1a;
    padding: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    transition: transform 0.3s ease-in-out;
    color: white;
  }

  .nav-links-mobile .nav-link {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .search-bar{
    margin-right: -30px;
  }
  

  .search-bar input {
    font-size: 14px;
    width: 100%;
  }

  .search-bar button {
    width: 100%;
    font-size: 14px;
  }
}



@media screen and (max-width: 1530px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: block;
    margin-right: 150px;
  }
  .logo{
    margin-left: 30px;
  }

  .nav-links-mobile {
    flex-direction: column;
    width: 100%;
    background: #1a1a1a;
    padding: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    transition: transform 0.3s ease-in-out;
    color: white;
  }

  .nav-links-mobile .nav-link {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .search-bar {
    width: 50%;
    margin-top: 10px;
    margin-right: -50px;
  }

  .search-bar input {
    font-size: 14px;
    width: 100%;
  }

  .search-bar button {
    width: 100%;
    font-size: 14px;
  }
}



@media screen and (max-width: 792px) and (max-height: 800px) {
  .navbar-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .logo {
    width: 120px;
  }

  .hamburger {
    font-size: 26px;
    margin-left: 100%;
  }

  .nav-links-mobile {
    font-size: 16px;
  }

  .search-bar input {
    font-size: 12px;
  }

  .search-bar button {
    font-size: 12px;
  }
  .search-bar{
    margin-left: 40px;

  }

  
  
}
