
  /* Only Apply Background to Contact Page */
.main-container {
  background-image: url('./assets/streetview.jpg'); /* Ensure path is correct */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: Arial, sans-serif;
  color: white;
  min-height: 100vh; /* Full height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark Overlay for Better Contrast */
.contact-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Darkens the background */
  z-index: -1;
}
  .services-page {
    padding: 2rem;
    text-align: center;
  }
  
  .services-header {
    background-color: #cead0798;
    color: rgb(0, 0, 0);
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    font-family: "Baskervville", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .services-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  .services-h1{
  font-family: "Baskervville", serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
  color: black;
  background-color: rgba(65, 60, 60, 0.255);
  }
  .services-header p {
    font-size: 1.2rem;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .service-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 300px;
    padding: 1.5rem;
    text-align: left;
  }
  
  .service-card h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #a79f05;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #333;
  }


  .service-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .services-img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  /* Button Styling */
.service-card button {
  background-color: #d4af37; /* Gold */
  color: #000;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.quote-link button{
  background-color: #cb9f19d9;
  transform: translateY(-3px);
  color: white;
  
}

.service-card button:hover {
  background-color: #b8962e;
  transform: translateY(-3px);
  
}

/* Details Container */
/* Main Flex Container */
.details-container {
  
  display: flex;
  align-items: flex-start; /* Aligns images and text at the top */
  justify-content: center; /* Centers the content */
  gap: 40px; /* Space between images and text */
  max-width: 1200px;
  margin: auto;
  padding: 60px 20px;
  position: relative;
  z-index: 1;
  color:black
    
    
}

/* Image Container - Vertical Layout */
.details-images {
  display: flex;
  flex-direction: column; /* Align images vertically */
  align-items: center;
  gap: 20px; /* Space between images */
}

/* Image Styling */
.detail-img {
  padding-top: 55px;
  max-width: 325px; /* Adjust image width */
  height: auto;
  border-radius: 10px;
}

/* Content Section */
.details-content {
  flex: 1; /* Ensures text takes up available space */
  text-align: left;
  background-color: #00000046;
  border: #b8962e63 2px solid;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.details-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000046;
  z-index: -1;
}


/* Headings */
.details-content h2 {
  font-size: 28px;
  color: white; /* Gold color */
  margin-bottom: 20px;
  margin-left: 100px;

}

.details-content h5 {
  font-size: 20px;
  color: goldenrod; /* Gold color */
  margin-bottom: 5px;
  font-weight: bold;
} 

.details-content h6 {
  font-size: 18px;
  font-weight: bold;
  color: #c2980e;
}

/* Paragraph Text */
.details-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color:white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: rgba(36, 34, 34, 0.218);
}

/* Mobile Screens (Max Width: 768px) */
@media screen and (max-width: 768px) {
  .details-container {
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
  }

  .details-images {
    flex-direction: column;
  }

  .details-content {
    padding: 20px;
  }

  .details-content h2 {
    font-size: 22px;
  }

  .details-content p {
    font-size: 14px;
  }

  .contact-container {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .services-container button {
    margin-left: 180px;
  }

  .services-container{
    flex-direction: column;
    text-align: center;
    
  }


  .service-image{
    margin-left: 200px;

  }

  .contact-info {
    margin-left: 0;
  }

  .social-links {
    margin-top: 20px;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    font-size: 14px;
  }

  .submit-btn {
    width: 100%;
    font-size: 14px;
    padding: 10px;
  }

  .status-message {
    font-size: 14px;
  }

  .services-container {
    flex-direction: column;
  }

  .service-card {
    width: 90%;
    margin: auto;
  }

  .service-card button{
    margin-left: 220px;
  }


  .services-header h1 {
    font-size: 2rem;
  }

  .services-header p {
    font-size: 1rem;
  }
  .details-images {
    flex-direction: row; /* Stack images horizontally on small screens */
    justify-content: center;
    
  }

  .detail-img{
    padding-top: 0px;
    max-width: 100px;
  }


}

/* Small Mobile Screens (Max Width: 480px) */
@media screen and (max-width: 480px) {
  .details-container {
    padding: 20px 5px;
  }

  .details-content {
    padding: 15px;
  }

  .details-content h2 {
    font-size: 20px;
    margin-left: 0;
    text-align: center;
  }

  .details-content p {
    font-size: 12px;
  }

  .services-header h1 {
    font-size: 1.8rem;
  }

  .services-header p {
    font-size: 0.9rem;
  }

  .social-links h3 {
    font-size: 18px;
  }

  .service-card {
    width: 100%;
    padding: 15px;
  }

  .service-card h2 {
    font-size: 1.3rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 12px;
    padding: 10px;
  }

  .services-container button {
    margin-left: 100px;
  }

  .services-container{
    flex-direction: column;
    text-align: center;

  }

  .submit-btn {
    font-size: 12px;
    padding: 10px;
  }

  .status-message {
    font-size: 12px;
  }
}