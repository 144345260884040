
/* General Styles */
.main-content {
  padding: 20px;
  min-height: 100vh; /* Ensures the content takes at least the viewport height */
}

.homepage-container {
  text-align: center;
  /* Set background to black */
}

/* Hero Section Styling */
.hero-section {
  position: relative;
  margin-top: 0;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-content {
  position: absolute; /* Position the content on top of the image */
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center content */
  text-align: center; /* Center align the text */
  color: #fff; /* White text for contrast */
  z-index: 2; /* Ensure the content is above the image */
}

.homepage-img {
  width: 100%;
 
  height: 120%;
  object-fit: cover; /* Ensures the image covers the section without distortion */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Place the image below the content */
  
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.cta-button {
  background: #0a0c0e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background: #9e7d04;
}

/* About Section */
.about-section {
  padding: 2rem;
  background: #f4f4f4;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Services Section */
.services-section {
  padding: 2rem;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-item {
  background: #f9f9f9;
  margin: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  width: 250px;
  border-radius: 5px;
}

.service-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

/* Chatbot Section */
.chatbot-section {
  padding: 2rem;
  background: #deb409;
  color: white;
}

.chatbot-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Testimonials Section */
.testimonials-section {
  padding: 2rem;
  background: #f4f4f4;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Footer */
.footer {
  padding: 1rem;
  background: #333;
  color: white;
}

.footer-links a {
  color: #deb409;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
.service-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white;
  background-color: #aa9708;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.service-link:hover {
  background-color: #000000;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-section {
    height: auto; /* Allow height to adjust based on content */
    padding: 2rem 0;
  }

  .hero-content {
    position: static; /* Remove absolute positioning */
    transform: none; /* Reset transform */
    text-align: center; /* Ensure text is centered */
   
  }

  .hero-section h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .hero-section p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .cta-button {
    padding: 10px 15px; /* Reduce button padding */
    font-size: 0.9rem; /* Adjust font size */
  }

  .services-list {
    flex-direction: column; /* Stack service items vertically */
  }

  .service-item {
    width: 90%; /* Make service items span most of the screen */
    margin: 10px auto; /* Center service items */
  }

  .testimonials-section h2,
  .chatbot-section h2,
  .about-section h2,
  .services-section h2 {
    font-size: 2rem; /* Reduce heading size */
  }
}

.testimonials-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.testimonials-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonials-section p {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #666;
}

.reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 20px;
}

.media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.review-video {
  max-width: 45%; /* Adjust video width */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.review-image {
  max-width: 45%; /* Adjust image width */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.review-link p {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

.google-link {
  display: inline-block;
  margin-top: 10px;
  color: #4285f4;
  font-weight: bold;
  text-decoration: none;
}

.google-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .media-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .review-video,
  .review-image {
    max-width: 90%; /* Adjust size for smaller screens */
  }
}

.thank-you-section {
  margin-top: 40px;
  text-align: center;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  width: 80%; /* Centered and width-constrained */
  margin-left: auto;
  margin-right: auto;
}

.thank-you-section h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.thank-you-section p {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #555;
  line-height: 1.8;
}

.google-link {
  color: #4285f4;
  font-weight: bold;
  text-decoration: none;
}

.google-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .thank-you-section {
    width: 90%; /* Adjust width for smaller screens */
    padding: 20px;
  }

  .thank-you-section h3 {
    font-size: 1.6rem;
  }

  .thank-you-section p {
    font-size: 1rem;
  }
}
@keyframes slide-across {
  0% {
    left: -100%; /* Start off-screen to the left */
  }
  50% {
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
  }
  100% {
    left: 100%; /* Move off-screen to the right */
  }
}

.promo-banner {
  position: absolute;
  z-index: 2;
  top: 10%; /* Adjust vertical placement */
  left: 0; /* Initial position for animation */
  width: 100%; /* Full width */
  text-align: center;
  color: #ffffff;
  margin-top: 6%;
  font-weight: bold;
  
  
  
}

